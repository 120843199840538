import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { register, finishRegister } from '../../http/userAPI';
import { ActionButton, Input, PasswordInput, Select } from '../../ui-kit/components';
import styles from './SignUp.module.scss';
import { MAIN_ROUTER } from '../../utils/consts'
import { actionSetIsAuth, actionSetUserData } from '../../store/actionCreators/userActionCreator';
import { alertSuccess, alertDanger } from '../../utils/function';
import { getCountries } from '../../http/siteAPI';
import { useEffect } from 'react';

export const SignUp = () => {

  const navigate = useNavigate()
  const dispatch = useDispatch()

  const lang = useSelector((state) => state.site.lang)
  const language_id = useSelector((state) => state.site.language_id)
  const isAuth = useSelector(store => store.user.isAuth)

  const [countries, setCountries] = useState([])
  const [email, setEmail] = useState('')
  const [name, setName] = useState('')
  const [lastname, setLastname] = useState('')
  const [secondname, setSecondname] = useState('')
  const [country, setCountry] = useState('')
  const [city, setCity] = useState('')
  const [address, setAddress] = useState('')
  const [index, setIndex] = useState('')
  const [password, setPassword] = useState('')
  const [confirm_password, setConfirmPassword] = useState('')

  const [step, setStep] = useState(1)
  const [code, setCode] = useState('')


  const finishSignUp = async () => {

    if (code.length != 4) {
      alertDanger(dispatch,lang['login_signup_register_code'])
      return
    }
    
    if (isAuth) {
      navigate(MAIN_ROUTER)
    }

    try {

      let data = await finishRegister(
        email, 
        name, 
        lastname, 
        secondname, 
        country, 
        city, 
        address, 
        index, 
        password, 
        confirm_password, 
        language_id, 
        code
      )

      dispatch(actionSetIsAuth(true))
      dispatch(actionSetUserData(data)) 

      navigate(MAIN_ROUTER)

    } catch(e) {
        
      alertDanger(dispatch,e.response.data.message)

    }

  }

  const clickSignUp = async () => {

    if (isAuth) {
      navigate(MAIN_ROUTER)
    }
    
    try {

      let data = await register(
        email, 
        name, 
        lastname, 
        secondname, 
        country, 
        city, 
        address, 
        index, 
        password, 
        confirm_password, 
        language_id
      )

      setStep(2)

    } catch(e) {
        
      alertDanger(dispatch,e.response.data.message)

    }

  }

  const _load_async = async() => {
    let data = await getCountries()
    setCountries(data)
  }

  useEffect(() => {
    _load_async()
  }, [])


  return (
    <div className={styles.body}>
      <h1 className={styles.title}>{ lang['registration_title'] }</h1>
      <div className={styles.form}>

        { step == 1 && (
          <>
            <Input autocomplete="off" className={styles.input} setInputValue={(val) => setEmail(val)} label='E-mail' dynamicLabel />
            <Input autocomplete="off" className={styles.input} setInputValue={(val) => setLastname(val)} label={ lang['registration_lastname'] } dynamicLabel />
            <Input autocomplete="off" className={styles.input} setInputValue={(val) => setName(val)} label={ lang['registration_name'] } dynamicLabel />
            <Input autocomplete="off" className={styles.input} setInputValue={(val) => setSecondname(val)} label={ lang['registration_secondname'] } dynamicLabel />
            <Input autocomplete="off" className={styles.input} setInputValue={(val) => setCountry(val)} label={ lang['registration_country'] } datalist={countries} dynamicLabel />
            <Input autocomplete="off" className={styles.input} setInputValue={(val) => setCity(val)} label={ lang['registration_city'] } dynamicLabel />
            <Input autocomplete="off" className={styles.input} setInputValue={(val) => setAddress(val)} label={ lang['registration_address'] } dynamicLabel />
            <Input autocomplete="off" className={styles.input} setInputValue={(val) => setIndex(val)} label={ lang['registration_index'] } dynamicLabel />
            <PasswordInput autocomplete="off" className={styles.input} setInputValue={(val) => setPassword(val)} label={ lang['registration_pass'] } />
            <PasswordInput autocomplete="off" className={styles.input} setInputValue={(val) => setConfirmPassword(val)} label={ lang['registration_pass_confirm'] } dynamicLabel />
            <Link className={styles.link} to='/sign-in'>
              { lang['registration_have_account'] }
            </Link>
          </>
        ) }

        { step == 2 && (
          <>
            <div style={{marginTop: 10, maxWidth: 300, textAlign: 'center'}}>{ lang['login_page_code_title'] }</div>
            <div style={{marginBottom: 10, marginTop: 10}}>
              <div><Input autocomplete="off" className={styles.input} setInputValue={(val) => setCode(val)} label={ lang['login_page_code_label'] } dynamicLabel /></div>
            </div>
          </>
        ) }

        <ActionButton className={styles.button} onClick={() => step == 1 ? clickSignUp() : finishSignUp()}>{ step == 1 ? lang['start_now'] : lang['register_finish'] }</ActionButton>
      </div>
      <p className={styles.disclaimer}>
        { lang['registration_if_click'] }{' '}
        <Link className={styles.disclaimer__link} to='/page/user-agreement'>
          { lang['login_agreement'] }
        </Link>{' '}
        { lang['login_and'] }{' '}
        <Link className={styles.disclaimer__link} to='/page/privacy-policy'>
          { lang['login_policy'] }
        </Link>
      </p>
    </div>
  );
};
