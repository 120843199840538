/** CHANGE ACTION */
export const ACTION_CHANGE_CURRENCY = "ACTION_CHANGE_CURRENCY"

/** SELECT ACTION */
export const ACTION_SET_GRAPH = "ACTION_SET_GRAPH"
export const ACTION_SET_BEST_PAIR = "ACTION_SET_BEST_PAIR"
export const ACTION_SET_MARKET_LIST = "ACTION_SET_MARKET_LIST"
export const ACTION_SET_COURCE_CURRENCY = "ACTION_SET_COURCE_CURRENCY"
export const ACTION_SET_AUTO_LIST = "ACTION_SET_AUTO_LIST"
export const ACTION_SET_TRADEAGENT_LIST = "ACTION_SET_TRADEAGENT_LIST"
export const ACTION_SET_CURRENCY_LIST = "ACTION_SET_CURRENCY_LIST"
export const ACTION_SET_CURRENCY_EXCHANGE = "ACTION_SET_CURRENCY_EXCHANGE"
export const ACTION_SET_BALANCE_LIST = "ACTION_SET_BALANCE_LIST"
export const ACTION_SET_TRADE_RESULT = "ACTION_SET_TRADE_RESULT"
export const ACTION_SET_TRADE_RESULT_ONLINE = "ACTION_SET_TRADE_RESULT_ONLINE"
export const ACTION_SET_BID_MID = "ACTION_SET_BID_MID"
export const ACTION_SET_ASK_MID = "ACTION_SET_ASK_MID"
export const ACTION_SET_ORDER_AMOUNT = "ACTION_SET_ORDER_AMOUNT"
export const ACTION_SET_CALCULATION = "ACTION_SET_CALCULATION"
export const ACTION_SET_SERVER_TIME = "ACTION_SET_SERVER_TIME"
export const ACTION_SET_AGENT_BUY_ID = "ACTION_SET_AGENT_BUY_ID"
export const ACTION_SET_AGENT_SELL_ID = "ACTION_SET_AGENT_SELL_ID"
export const ACTION_SET_GRAPH_ASK = "ACTION_SET_GRAPH_ASK"
export const ACTION_SET_GRAPH_BID = "ACTION_SET_GRAPH_BID"