import { Link, useNavigate } from 'react-router-dom'
import { ActionButton, Input, PasswordInput } from '../../ui-kit/components'
import styles from './SignIn.module.scss'
import { useState } from 'react'
import { finishLogin, login } from '../../http/userAPI'
import store from '../../store/store'
import { alertSuccess, alertDanger } from '../../utils/function';
import { 
  actionSetIsAuth,
  actionSetUserData
} from '../../store/actionCreators/userActionCreator'
import { MAIN_ROUTER } from '../../utils/consts'
import { useSelector, useDispatch } from 'react-redux'

export const SignIn = () => {

  const dispatch = useDispatch()
  
  const lang = useSelector((state) => state.site.lang)
  const language_id = useSelector((state) => state.site.language_id)
  
  const [email,setEmail] = useState('')
  const [password,setPassword] = useState('')
  const [step, setStep] = useState(1)
  const [code, setCode] = useState('')

  const navigate = useNavigate()

  const isAuth = useSelector(store => store.user.isAuth)


  if (isAuth) {
    navigate(MAIN_ROUTER)
  }




  const finishSendForm = async (e) => {

    e.preventDefault()

    try {

      let data = await finishLogin(email,password,code,language_id)
      
      dispatch(actionSetIsAuth(true))
      dispatch(actionSetUserData(data)) 

      navigate(MAIN_ROUTER)
      
    } catch(e) {
        
      alertDanger(dispatch,e.response.data.message)

    }
    
  }


  const sendForm = async (e) => {

    e.preventDefault()

    try {
      await login(email,password,language_id)
      setStep(2)
    } catch(e) {
      alertDanger(dispatch,e.response.data.message)
    }
    
  }
  
  return (
    <div className={styles.body}>
      <h1 className={styles.title}>{ lang['login_to_crossceed'] }</h1>
      <form className={styles.form}>
        { step == 1 && (
          <>
            <Input className={styles.input} setInputValue={setEmail} label='E-mail' dynamicLabel />
            <PasswordInput setInputValue={setPassword} className={styles.input} />
            <div className={styles.links}>
              <Link className={styles.link} to='/password-restore'>
                { lang['login_remember_pass'] }
              </Link>
              <Link className={styles.link} to='/sign-up'>
                { lang['login_create_new_acc'] }
              </Link>
            </div>
          </>
        ) }

        { step == 2 && (
          <>
            <div style={{marginTop: 10, maxWidth: 250, textAlign: 'center'}}>{ lang['login_page_code_title'] }</div>
            <div style={{marginBottom: 10, marginTop: 10}}>
              <div><Input autocomplete="off" className={styles.input} setInputValue={(val) => setCode(val)} label={ lang['login_page_code_label'] } dynamicLabel /></div>
            </div>
          </>
        ) }

        <ActionButton onClick={(e) => step == 1 ? sendForm(e) : finishSendForm(e) } className={styles.button}>{ lang['login'] }</ActionButton>

      </form>
      
      <p className={styles.disclaimer}>
        { lang['login_auth'] }{' '}
        <Link className={styles.disclaimer__link} to='/page/user-agreement'>
          { lang['login_agreement'] }
        </Link>{' '}
        { lang['login_and'] }{' '}
        <Link className={styles.disclaimer__link} to='/page/privacy-policy'>
          { lang['login_policy'] }
        </Link>
      </p>
    </div>
  );
};
