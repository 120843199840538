import classNames from 'classnames';
import { Navigate, NavLink } from 'react-router-dom';
import { ActionButton, ColoredText } from '../../ui-kit/components';

import { ReactComponent as BalanceIcon } from './assets/Balance.svg';
import { ReactComponent as SupportIcon } from './assets/Support.svg';
import { ReactComponent as TradeIcon } from './assets/Trade.svg';
import { ReactComponent as HistoryIcon } from './assets/History.svg';
import { ReactComponent as SignOutIcon } from './assets/SignOut.svg';

import styles from './MobileNav.module.scss';
import { BALANCE_ROUTER, HISTORY_ROUTER, MAIN_ROUTER, SIGNIN_ROUTER, SUPPORT_ROUTER, TRADE_ROUTER } from '../../utils/consts';
import { actionSetIsAuth, actionSetUserData } from '../../store/actionCreators/userActionCreator';
import { useDispatch, useSelector } from 'react-redux';
import { useWindowSize } from '../../hooks';
import { useEffect } from 'react';

export const MobileNav = () => {
  
  const dispatch = useDispatch()

  const { isMobile } = useWindowSize();

  console.log("-------- isMobile --------")
  console.log(isMobile)

  const isAuth = useSelector((state) => state.user.isAuth)

  const logOut = (e) => {

    e.preventDefault()
    
    dispatch(actionSetIsAuth(false))
    dispatch(actionSetUserData({}))

    delete localStorage.token;
    delete localStorage.pass;
    delete localStorage.email;

    Navigate(MAIN_ROUTER)
    
  }


  return (
    <div className={styles.wrapper}>
      <nav className={styles.nav}>
        <div className={styles['nav__button-group']}>
          { isAuth && 
          <NavLink to={ BALANCE_ROUTER } className={styles['nav__button-wrapper']}>
            {({ isActive }) => (
              <div className={styles.nav__button}>
                <BalanceIcon
                  className={classNames(styles['nav__button-icon'], {
                    [styles['nav__button-icon--active']]: isActive,
                  })}
                />
                <ColoredText color={isActive ? 'green' : 'white'}>Баланс</ColoredText>
              </div>
            )}
          </NavLink> }

          { isAuth && 
          <NavLink to={ SUPPORT_ROUTER } className={styles['nav__button-wrapper']}>
            {({ isActive }) => (
              <div className={styles.nav__button}>
                <SupportIcon
                  className={classNames(styles['nav__button-icon'], {
                    [styles['nav__button-icon--active']]: isActive,
                  })}
                />
                <ColoredText color={isActive ? 'green' : 'white'}>Поддержка</ColoredText>
              </div>
            )}
          </NavLink> }
          
        </div>
        <div className={styles['nav__button-group']}>
          { isAuth && 
          <NavLink to={ HISTORY_ROUTER } className={styles['nav__button-wrapper']}>
            {({ isActive }) => (
              <div className={styles.nav__button}>
                <HistoryIcon
                  className={classNames(styles['nav__button-icon'], {
                    [styles['nav__button-icon--active']]: isActive,
                  })}
                />
                <ColoredText color={isActive ? 'green' : 'white'}>История</ColoredText>
              </div>
            )}
          </NavLink> }

          { isAuth ? 
          <NavLink to={false} onClick={e => logOut(e)} className={styles['nav__button-wrapper']}>
            {({ isActive }) => (
              <div className={styles.nav__button}>
                <SignOutIcon
                  className={classNames(styles['nav__button-icon'], {
                    [styles['nav__button-icon--active']]: isActive,
                  })}
                />
                <ColoredText color={isActive ? 'green' : 'white'}>Выйти</ColoredText>
              </div>
            )}
          </NavLink> 
            : 
          <NavLink to={SIGNIN_ROUTER} className={styles['nav__button-wrapper']}>
            {({ isActive }) => (
              <div className={styles.nav__button}>
                <SignOutIcon
                  className={classNames(styles['nav__button-icon'], {
                    [styles['nav__button-icon--active']]: isActive,
                  })}
                />
                <ColoredText color={isActive ? 'green' : 'white'}>Войти</ColoredText>
              </div>
            )}
          </NavLink>

          }

        </div>
      </nav>
      { isAuth && 
      <div className={styles.trade}>
        <ActionButton type='link' to={ TRADE_ROUTER } className={styles.trade__button}>
          <TradeIcon className={styles.trade__icon} />
        </ActionButton>
      </div> }
    </div>
  );
};
