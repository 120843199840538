import classNames from 'classnames';

import { ColoredText, ActionButton } from '../../../ui-kit/components';
import { LandingPageCard } from '../LandingPageCard';
import { BTC, ETH } from '../../../ui-kit/assets';

import styles from './HeroSection.module.scss';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { getDataLandingPage } from '../../../http/tradeAPI';

export const HeroSection = () => {

  const lang = useSelector((state) => state.site.lang)

  const [btc_price, setBtcPrice] = useState('')
  const [eth_price, setEthPrice] = useState('')

  const [btc_diff_price, setDiffBtcPrice] = useState('')
  const [eth_diff_price, setDiffEthPrice] = useState('')

  const isAuth = useSelector((state) => state.user.isAuth)

  const load_data = async () => {

    let data = await getDataLandingPage()
    console.log(data)

    let btc_price_temp = data.rate.data.filter((i) => i.symbol === 'BTC')[0].price_usd
    let eth_price_temp = data.rate.data.filter((i) => i.symbol === 'ETH')[0].price_usd

    let btc_diff_price_temp = data.rate.data.filter((i) => i.symbol === 'BTC')[0].percent_change_24h
    let eth_diff_price_temp = data.rate.data.filter((i) => i.symbol === 'ETH')[0].percent_change_24h

    setBtcPrice(btc_price_temp)
    setEthPrice(eth_price_temp)

    setDiffBtcPrice(btc_diff_price_temp)
    setDiffEthPrice(eth_diff_price_temp)

  }
  
  useEffect(() => {
    load_data()
  },[])

  return (
    <section className={styles.body}>
      <div className={styles['text-block']}>
        <h1 className={styles.title}>
          {lang['landing_page_title']} <ColoredText color='green'>{ lang['landing_page_title_highlighted'] }</ColoredText>
        </h1>
        <p className={styles.text}>{lang['landing_page_text']}</p>

        {isAuth ?
        <ActionButton type='link' to='/page/how-it-works'>
          {lang['main_page_how_it_work']}
        </ActionButton> :
        <ActionButton type='link' to='/sign-up'>
          {lang['sign_up']}
        </ActionButton>}

      </div>
      <div className={styles.cards}>
        <LandingPageCard
          className={classNames(styles.card, styles['card--1'])}
          price={btc_price}
          title='BTC'
          diff={btc_diff_price}
          time='24h'
          icon={BTC}
        />
        <LandingPageCard
          className={classNames(styles.card, styles['card--2'])}
          price={eth_price}
          title='ETH'
          diff={eth_diff_price}
          time='24h'
          icon={ETH}
        />
        <div className={styles['cards-bg--1']} />
        <div className={styles['cards-bg--2']} />
      </div>
    </section>
  );
};
