import styles from './DepositRefill.module.scss'
import { useEffect, useState } from 'react'
import { getOperation } from '../../http/siteAPI';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Parser from 'html-react-parser';
import { ActionButton, Card, ControlButton } from '../../ui-kit/components';
import {QRCodeCanvas} from 'qrcode.react';


export const DepositRefill = () => {

  const language_id = useSelector((state) => state.site.language_id)
  const lang = useSelector((state) => state.site.lang)
  const [operation,setOperation] = useState({msg: '', msg_balance: ''})

  const [wallet_address, setWalletAddress] = useState('')
  const [tag, setTag] = useState('')

  const {address} = useParams()

  const load_data = async() => {
    let data = await getOperation(address, language_id)
    setOperation(data)

    let temp = address.split('|')
    if (temp.length == 2) {

      setWalletAddress(temp[0])
      setTag(temp[1])

    } else {

      setWalletAddress(address)
      setTag("")

    }

    console.log(data)
  }

  useEffect(() => {
    load_data()
  },[])

  const imageSettings = {
    width: 50
  }

  return (
    <div className={styles.body}>
      <Card className={styles.card}>
        
        <div>
          <p>{ operation.balance_send } <span className={styles.green}>{ operation.value } { operation.symbol }</span> { operation.balance_to_this_address }</p>
          <br/>
          <p><strong className={styles.green_address}>{ wallet_address }</strong></p>
          { tag != '' && (
            <>
              <br/>
              <p>{ lang['balance_refill_tag'] }</p>
              <br/>
              <p><strong className={styles.green_address}>{ tag }</strong></p>    
              <br/>
            </>
          )}
        </div>
        <br/>
        <div style={{textAlign: 'center'}}>
          { wallet_address !== "" && <QRCodeCanvas size={128} imageSettings={imageSettings} value={wallet_address} /> }
         
          <br/>
          <br/>
          <div>{ Parser(operation.msg_balance) }</div>
          <br/>
          <br/>
          <ActionButton type="link" to={'/balance'} className={styles.button}>{ lang['deposit_go_to_balance'] }</ActionButton>
        </div>
      </Card>
    </div>
  );
};
