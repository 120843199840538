export const SIGNIN_ROUTER = '/sign-in'
export const SIGNUP_ROUTER = '/sign-up'
export const MAIN_ROUTER = '/'
export const BALANCE_ROUTER = '/balance'
export const NEWS_ROUTER = '/news'
export const NEWS_ONE_ROUTER = '/news/:id'
export const HISTORY_ROUTER = '/history'
export const EXCHANGE_ROUTER = '/exchange'
export const SUPPORT_ROUTER = '/support'
export const TRADE_ROUTER = '/trade'
export const VERIFICATION_ROUTER = '/verification'
export const VIP_ROUTER = '/vip'
export const CHANGE_PASSWORD_ROUTER = '/change-password'
export const REFERAL_ROUTER = '/referal'
export const PAGE_ROUTER = '/page/:slug'
export const DEPOSIT_ROUTER = '/balance/deposit/:symbol/:uid'
export const WITHDRAWAL_ROUTER = '/balance/withdrawal/:symbol'
export const DEPOSIT_REFILL_ROUTER = '/balance/deposit-refill/:address'
export const SUPPORT_INFORMATION_ROUTER = '/support/information/:id'
export const PASSWORD_RESTORE_ROUTER = '/password-restore'
export const PASSWORD_RESTORE_CREATE_ROUTER = '/restore-password-create/:uid'
export const RESTORE_EMAIL_SENT_ROUTER = '/restore-email-sent'
export const CONFIRM_EMAIL_ROUTER = '/confirm-email/:uid'