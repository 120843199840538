import classNames from 'classnames';
import { useEffect, useMemo, useState } from 'react';
import { ActionButton, Select, Input, ColoredText } from '../../ui-kit/components';
import { SelectOption } from '../../components/Deposit';

import { ReactComponent as ExchangeIcon } from './assets/Exchange.svg';
import { alertSuccess, alertDanger } from '../../utils/function';
import { AVAX } from '../../ui-kit/assets';
import styles from './Exchange.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { exchange, getCourceCurrency, getCurrency, getCurrencyCoursePaykassa, getCurrencyExchange, getDataExchange } from '../../http/tradeAPI';
import { actionSetCourceCurrency, actionSetCurrencyExchange, actionSetCurrencyList } from '../../store/actionCreators/tradeActionCreator';
import { currencies } from '../../utils/currencies';

export const Exchange = () => {

  const dispatch = useDispatch()


  const [ currencies_list, setCurrenciesList ] = useState([])
  const [ options, setOptions ] = useState([])
  const [ availible, setAvailible ] = useState('...')
  const [ availible_number, setAvailibleNumber ] = useState(0)
  const [ from, setFrom ] = useState('')
  const [ to, setTo ] = useState('')
  const [ balance_crypto, setBalanceCrypto ] = useState([])
  const [ user_balance, setUserBalance ] = useState([])
  const [ amount_from, setAmountFrom ] = useState('')
  const [ amount_to, setAmountTo ] = useState('')
  const language_id = useSelector((state) => state.site.language_id)

  const [ selectedOptionFrom, setSelectedOptionFrom ] = useState(null)
  const [ selectedOptionTo, setSelectedOptionTo ] = useState(null)

  const lang = useSelector((state) => state.site.lang)

  const onChangeFrom = (val) => {
    setFrom(val.value)
    let bal = balance_crypto.filter((i) => i.currency_id === val.value)[0]?.value ?? 0
    console.log(balance_crypto)
    let symbol = currencies_list.filter((i) => i.id === val.value)[0].symbol
    setAvailible(bal + ' ' + symbol)
    setAvailibleNumber(bal)
    setSelectedOptionFrom(val)
  }
  
  
  const onChangeTo = (val) => {
    setTo(val.value)
    setSelectedOptionTo(val)
  }

  const load_data = async () => {

    let data = await getDataExchange()
    setBalanceCrypto(data.user_balance)
    setCurrenciesList(data.currencies)

    let temp = []
    data.currencies.map((item) => {
      temp.push({
        value: item.id,
        Component: () => <SelectOption icon={currencies[item.symbol]} text={item.symbol} />,
      })
    })
    setOptions(temp)

    setUserBalance(data.user_balance)

    console.log(data)
  }

  useEffect(() => {
    
    load_data()

  },[])

  const calculate = async () => {
    if (amount_from !== '' && from !== '' && to !== '') {
      let result = await getCurrencyCoursePaykassa(language_id, from, to, amount_from)
      if (result.error === false) {
        setAmountTo(result.amount_to)
      } else {
        alertDanger(result.error)
      }
    }
  }

  useEffect(() => {

    calculate(amount_from, from, to)

  }, [amount_from, from, to])
  

  const showAllData = () => {

    let balance = user_balance.filter((i) => i.currency_id === from)
    if (balance.length > 0) {
      balance = balance[0].value
      if (availible !== '...' && Number(balance) > availible_number ) {
        setAmountFrom(availible_number)
      } else {
        setAmountFrom(balance)
      }
    } else {
      setAmountFrom(0)
    }
  }

  const rotateCurrency = () => {
    
    setAmountTo('')

    setSelectedOptionFrom(selectedOptionTo)
    setSelectedOptionTo(selectedOptionFrom)

    setFrom(selectedOptionTo.value)
    let bal = balance_crypto.filter((i) => i.currency_id === selectedOptionTo.value)[0]?.value ?? 0
    let symbol = currencies_list.filter((i) => i.id === selectedOptionTo.value)[0].symbol
    setAvailible(bal + ' ' + symbol)
    setAvailibleNumber(bal)

    setTo(selectedOptionFrom.value)

  }

  const exchangeHandler = async () => {

    try {
      let data_exchange = await exchange(language_id, from, to, amount_from)
      setAvailible('...')
      setAvailibleNumber(0)
      setFrom('')
      setAmountFrom('')
      setTo('')
      setAmountTo('')
      alertSuccess(dispatch,data_exchange?.message)
    } catch (e) {
      alertDanger(dispatch,e.response.message)
    }

  }



  return (
    <div className={styles.body}>
      <h1 className={styles.title}>{ lang['info_title_4'] }</h1>
      <div className={styles.form}>
        <div className={styles.input}>
          <div className={styles['label-and-avaliable']}>
            <div className={styles.input__label}>{ lang['exchange_from'] }</div>
            <div className={styles['balance-info__text']}>
              { lang['withdrawal_avaliable'] }: <ColoredText color='gold'>{ availible }</ColoredText>
            </div>
          </div>
          <Select onChange={onChangeFrom} selected_option={selectedOptionFrom} options={options} placeholder={ lang['withdrawal_choose_crypto'] } />
        </div>
        <div className={classNames(styles.input, styles['all-amount-input'])}>
          <div className={styles.input__label}>{ lang['qty'] }</div>
          <Input type="number" step="any" className={styles.input__wrapper} defvalue={amount_from} setInputValue={(val) => setAmountFrom(val)} />
          <ColoredText onClick={() => showAllData()} className={styles['all-amount']} color='gold'>
            { lang['exchange_btn_all'] }
          </ColoredText>
        </div>
        <div className={styles.icon}>
          <ExchangeIcon onClick={() => rotateCurrency()} />
        </div>
        <div className={styles.input}>
          <div className={styles['label-and-avaliable']}>
            <div className={styles.input__label}>{ lang['exchange_to'] }</div>
          </div>
          <Select onChange={onChangeTo} selected_option={selectedOptionTo} options={options} placeholder={ lang['withdrawal_choose_crypto'] } />
        </div>
        <div className={styles.input}>
          <div className={styles.input__label}>{ lang['qty'] }</div>
          <Input type="number" step="any" className={styles.input__wrapper} defvalue={amount_to} setInputValue={(val) => setAmountTo(val)}/>
        </div>
        <ActionButton onClick={() => exchangeHandler()} className={styles.button}>{ lang['exchange_btn_suggest'] }</ActionButton>
      </div>
    </div>
  );
};
