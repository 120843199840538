import classNames from 'classnames';
import { useEffect } from 'react';
import React from 'react';
import { ColorRing } from 'react-loader-spinner';
import styles from './CustomLoader.module.scss';

export const CustomLoader = ({ loading }) => {
  if (loading === true) {
    return (
      <div className={styles['loader-overlay']}>
        <div className={styles['loader-container']}>
          <ColorRing  height={100} width={100} />
        </div>
      </div>
    );
  }
};
 